<div style="margin-top: 20px; margin-bottom: 20px">
  <div
    *ngIf="!enableTxtNoPessoaFisica"
    [formGroup]="selectedNoPessoaFisicaGroup"
  >
    <div
      *ngIf="allEmployees$ | async as allEmployees"
      style="float: left; height: 38px"
    >
      <mat-form-field class="custom-field" style="min-width: 384px">
        <mat-select
          formControlName="selectedNoPessoaFisicaFormControl"
          [(ngModel)]="selectedNoPessoaFisica"
          [disabled]="
            !isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
          "
           [ngClass]="{'error-highlight': hasNoPessoaFisicaError}"
        >
          <mat-option value="">Selecione um Colaborador</mat-option>
          <mat-option
            *ngFor="let employee of allEmployees"
            [value]="employee.noPessoaFisica"
          >
            {{ employee.noPessoaFisica }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="enableTxtNoPessoaFisica">
    <input
      type="text"
      class="input-custom"
      style="float: left; min-width: 384px"
      placeholder="Informe o nome de um Colaborador"
      [(ngModel)]="selectedTxtNoPessoaFisica"
      [ngClass]="{'error-highlight': hasNoPessoaFisicaError}"
    />
  </div>

  <select
    class="select-custom"
    [(ngModel)]="selectedPeriodo"
    style="margin-left: 20px; min-width: 384px; float: left"
    [disabled]="
      !isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
    "
    [ngClass]="{'error-highlight': hasPeriodoError}"
  >
    <option [ngValue]="null" selected>Selecione um Período</option>
    <!-- Updated to use null for no selection -->
    <option [ngValue]="7">1 Semana</option>
    <option [ngValue]="15">15 Dias</option>
    <option [ngValue]="30">1 Mês</option>
    <option [ngValue]="90">3 Meses</option>
    <option [ngValue]="180">6 Meses</option>
    <option [ngValue]="270">9 Meses</option>
  </select>

  <input
    type="button"
    value="Pesquisar"
    (click)="doSearch()"
    class="input-button-custom"
    style="margin-left: 20px"
    [disabled]="
      !isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
    "
  />
  <input
    type="button"
    value="Limpar"
    (click)="reset()"
    class="input-button-white-custom"
    style="margin-left: 20px"
    [disabled]="
      !isPerfilGestorAnvisaGestorAnvisaConsultaGestorCadastrosResponsavelLegalAdministrador
    "
  />
</div>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Nome/Login Column -->
  <ng-container matColumnDef="nomeLogin">
    <mat-header-cell *matHeaderCellDef> Nome/Login </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.autorNome }} / {{ row.autor }}
    </mat-cell>
  </ng-container>

  <!-- Data/Hora Column -->
  <ng-container matColumnDef="dataHora">
    <mat-header-cell *matHeaderCellDef> Data/Hora </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.dataAlteracao | date : "dd/MM/yyyy HH:mm" }}</mat-cell
    >
  </ng-container>

  <!-- Ação Column -->
  <ng-container matColumnDef="acao">
    <mat-header-cell *matHeaderCellDef> Ação </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ formatAction(row.enrichAuditAction) }}
    </mat-cell>
  </ng-container>

  <!-- Beneficiário Column -->
  <ng-container matColumnDef="beneficiario">
    <mat-header-cell *matHeaderCellDef> Beneficiário </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.beneficiarioNome }} </mat-cell>
  </ng-container>

  <!-- Sistema/Perfil Column -->
  <ng-container matColumnDef="sistemaPerfil">
    <mat-header-cell *matHeaderCellDef> Sistema/Perfil </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.sistemaNome }} / {{ row.perfilNome }}
    </mat-cell>
  </ng-container>

  <!-- Data Ativação Column -->
  <ng-container matColumnDef="dataAtivacao">
    <mat-header-cell *matHeaderCellDef> Data Ativação </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.dataAlteracao | date : "dd/MM/yyyy HH:mm" }}
    </mat-cell>
  </ng-container>

  <!-- Empresa/CNPJ Column -->
  <ng-container matColumnDef="empresaCnpj">
    <mat-header-cell *matHeaderCellDef> Empresa/CNPJ </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.empresaNome }} / {{ formatCnpj(row.empresaCnpj) }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator
  #paginatorPageSize
  [pageSizeOptions]="pageSizes"
  [length]="totalElements"
  (page)="nextPage($event)"
  showFirstLastButtons
></mat-paginator>
