<header class="br-header mb-4" id="header" data-sticky="data-sticky" style="z-index: 100;">
  <div class="container-lg">
    <div class="header-top">
      <!-- header Logo-->
      <div class="header-logo">
        <img src="https://www.gov.br/anvisa/++theme++padrao_govbr/img/govbr-logo-medium.png" alt="logo gov-br">
        <div class="header-sign">Ministério da Saúde</div>
      </div>
      <div class="header-actions">
        <div class="header-links dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown"
            aria-label="Abrir Acesso Rápido"><i class="fas fa-ellipsis-v" aria-hidden="true"></i>
          </button>
          <div class="br-list">
            <div class="header">
              <div class="title">Acesso Rápido</div>
            </div><a class="br-item" href="https://www.gov.br/pt-br/orgaos-do-governo">Órgãos do Governo</a>
            <a class="br-item" href="https://www.gov.br/acessoainformacao/pt-br">Acesso à Informação</a>
            <a class="br-item" href="http://www4.planalto.gov.br/legislacao">Legislação</a>
            <a class="br-item" href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">Acessibilidade</a>
          </div>
        </div><span class="br-divider vertical mx-half mx-sm-1"></span>
        <div class="header-functions dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown"
            aria-label="Abrir Funcionalidades do Sistema"><i class="fas fa-th" aria-hidden="true"></i>
          </button>
        </div>

        <div class="header-login">
          <div class="header-sign-in">
            <img src="../../assets/img/icon_logged_user.png" /> Olá, <strong>{{username}}</strong> <img
              style="margin-left: 4px;margin-bottom: 5px; cursor: pointer;" src="../../assets/img/icon_bottom_arrow.png"
              (click)="toggleDropdown()" />
            <div class="dropdown-content" [ngClass]="{'show': isDropdownOpen}">
              <a (click)="logout()">Sair da conta</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div>
      <div style="margin-top: 20px;" class="header-title">Cadastro Anvisa</div>
      <div style="margin-top: 10px;">
        <div *ngIf="isShowSelectEmpresa">
          <div *ngIf="allCompanies$ | async as allCompanies;" style="float: left; height: 38px;">
            <mat-form-field class="custom-field" style="min-width: 384px;">
              <mat-select [formControl]="selectedIdPessoaJuridica">
                <mat-option value="">Selecione uma Empresa</mat-option>
                <mat-option *ngFor="let company of allCompanies" [value]="company.id">
                  {{ company.razaoSocial }} <span *ngIf="company.nomeFantasia"> - {{ company.nomeFantasia }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>        
        <div style="float: right; position: relative;">
          <div style="float: left; position: relative; margin-top: 16px; margin-right: 10px;">
            <label for="profiles">Selecione um Perfil:</label>
          </div>
          <div class="toggle-container">
            <mat-button-toggle-group name="profiles" [(ngModel)]="selectedPerfil" (change)="onToggleChangePerfil($event.value)">
              <mat-button-toggle *ngFor="let perfil of perfis" [value]="perfil.noPerfil">
                {{ perfil.noPerfil }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>