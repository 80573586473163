<br />

<ng-container
  class="card-custom"
  *ngIf="
    (isPerfilGestorAnvisaGestorAnvisaConsulta || isPerfilAdministrador) &&
    isStCadastroAnvisa
  "
>
  <div class="custom-anvisa-label">
    <mat-icon class="business-icon">business_add</mat-icon>
    Organização Cadastrada pelo Cadastro Anvisa
  </div>
</ng-container>

<div class="card-custom" *ngIf="cnpj || cnes">
  <div *ngIf="cnpj && cnes">
    Colaboradores Vinculados ao CNPJ {{ cnpjFormatado }} e CNES {{ cnes }}
  </div>
  <div *ngIf="cnpj && !cnes">
    Colaboradores Vinculados ao CNPJ {{ cnpjFormatado }}
  </div>
  <div *ngIf="!cnpj && cnes">Colaboradores Vinculados ao CNES {{ cnes }}</div>
  {{ empresa }}
  <ng-container *ngIf="nomeFantasia && nomeFantasia.trim().length > 0">
    <br />{{ nomeFantasia }}
  </ng-container>
  <div *ngIf="pessoaJuridicaSerpro$ | async as pjSerpro">
    {{
      pjSerpro.getSituacaoAndMotivoDesc()
        ? pjSerpro.getSituacaoAndMotivoDesc()
        : "Informação sobre situação e motivo cadastral não disponível."
    }}
  </div>

  <div *ngIf="selectCompanyPorte$ | async as companyPorte">
    {{
      companyPorte
        ? "Porte: " + companyPorte.descricaoPorte
        : "Informação sobre porte não disponível."
    }}
  </div>
</div>

<!-- Container for "Colaboradores" label and filter buttons -->
<div style="display: flex; align-items: center; margin: 10px 10px">
  <!-- Colaboradores Label -->
  <span style="margin-right: 15px; font-weight: 500; font-size: 14px"
    >Colaboradores:</span
  >

  <!-- Active/Inactive Toggle Buttons with Tooltips and Conditional Disabled State -->
  <div style="display: flex; align-items: center; margin: 20px 0">
    <!-- Active/Inactive Toggle Buttons with Tooltips and Conditional Disabled State -->
    <div style="display: flex; align-items: center">
      <label
        [ngClass]="{ disabled: (selectedCompany$ | async) === undefined }"
        style="margin-right: 10px"
      >
        <input
          type="radio"
          [(ngModel)]="userStatusFilter"
          value="N"
          class="custom-radio"
          [disabled]="(selectedCompany$ | async) === undefined"
        />
        <!-- Tooltip for "Ativos" button -->
        <span
          matTooltip="Mostrar apenas colaboradores ativos"
          class="custom-radio-label active"
        >
          Ativos
        </span>
      </label>
      <label [ngClass]="{ disabled: (selectedCompany$ | async) === undefined }">
        <input
          type="radio"
          [(ngModel)]="userStatusFilter"
          value="S"
          class="custom-radio"
          [disabled]="(selectedCompany$ | async) === undefined"
        />
        <!-- Tooltip for "Inativos" button -->
        <span
          matTooltip="Mostrar apenas colaboradores inativos"
          class="custom-radio-label inactive"
        >
          Inativos
        </span>
      </label>
    </div>
  </div>

  <div style="width: 100%; height: 32px; margin-bottom: 20px">
    <div style="float: right">
      <a *ngIf="isPerfilAdministrador" routerLink="/integrador"
        ><img
          style="float: left"
          src="../../assets/img/icon_integration.png"
          border="0"
      /></a>
      <input
        routerLink="/log"
        type="button"
        value="Auditoria"
        class="input-button-white-custom"
        style="float: right; margin-left: 14px"
      />
      <input
        *ngIf="
          isPerfilAdministrador || isPerfilGestorAnvisaGestorAnvisaConsulta
        "
        type="button"
        value="Dashboard"
        class="input-button-white-custom"
        style="float: right; margin-left: 14px"
        (click)="openDashboardDialog()"
      />
    </div>
  </div>
</div>

<div style="margin-bottom: 20px">
  <select
    class="select-custom"
    [(ngModel)]="selectedOrgOrColab"
    #selectElement
    (change)="changePlaceholder(selectElement.value)"
    style="min-width: 284px; float: left"
  >
    <option *ngIf="!disableOrg" value="pesquisarOrganizacao">
      Pesquisar Organização
    </option>
    <option value="pesquisarColaborador">Pesquisar Colaborador</option>
    <option *ngIf="!disableInst" value="pesquisarInstituicao">
      Pesquisar Instituição
    </option>
  </select>

  <div class="input-container" style="margin-left: 20px; float: left">
    <input
      type="text"
      #inputSearchValue
      [placeholder]="placeholderText"
      [(ngModel)]="searchValue"
      (input)="onInputChange($event)"
      [ngClass]="{ 'error-highlight': hasInputError }"
      [attr.maxlength]="
        selectedOrgOrColab === 'pesquisarColaborador'
          ? 100
          : selectedOrgOrColab === 'pesquisarInstituicao'
          ? 7
          : 18
      "
    />
  </div>
  <input
    type="button"
    value="Pesquisar"
    (click)="continueSearch(inputSearchValue.value)"
    class="input-button-custom"
    style="margin-left: 20px"
  />
  <input
    type="button"
    value="Limpar"
    (click)="reset()"
    class="input-button-white-custom"
    style="margin-left: 20px"
  />

  <input
    type="button"
    value="Atribuir Perfis em Lote"
    class="input-button-white-custom"
    style="float: right"
    [disabled]="
      !isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador ||
      ((selectEmployees$ | async)?.length || 0) < 2
    "
    (click)="openDialogColabAtrPerfilLote('0ms', '0ms')"
  />
  <input
    type="button"
    value="Adicionar Colaborador"
    class="input-button-white-custom"
    style="float: right; margin-right: 20px"
    (click)="openDialogAddColaborador('0ms', '0ms')"
    [disabled]="
      (selectedCompany$ | async) === undefined ||
      !isPerfilGestorAnvisaGestorCadastrosResponsavelLegalAdministrador
    "
  />
</div>
<table mat-table [dataSource]="dataSourceTableToShow" class="mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">
      <input
        type="checkbox"
        (change)="toggleAllChb()"
        [checked]="isSelectAll"
      />
    </th>
    <td mat-cell *matCellDef="let element" style="width: 10%">
      <!-- Always allow checkbox action for isPerfilGestorAnvisa -->
      <input
        type="checkbox"
        [checked]="element.selected"
        (change)="toggleChb(element.idUsuarioRepresentante, !element.selected)"
        *ngIf="isPerfilGestorAnvisa || isPerfilAdministrador; else otherRoles"
      />

      <!-- Template for other roles with restricted editing capabilities -->
      <ng-template #otherRoles>
        <div>
          <!-- Enable checkbox if not Gestor Cadastros Responsavel Legal or not blocked -->
          <input
            type="checkbox"
            [checked]="element.selected"
            (change)="
              toggleChb(element.idUsuarioRepresentante, !element.selected)
            "
            *ngIf="
              !(
                isPerfilGestorCadastrosResponsavelLegal &&
                element.stBloqueado === 'S'
              );
              else blocked
            "
          />

          <!-- Disabled state presentation when blocked or restricted roles -->
          <ng-template #blocked>
            <input type="checkbox" [checked]="element.selected" disabled />
          </ng-template>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef style="width: 25%">Nome</th>
    <td mat-cell *matCellDef="let element" style="width: 25%" class="no-hover">
      {{ element.noPessoaFisica }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ativo">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Ativo</th>
    <td mat-cell *matCellDef="let element" style="width: 10%">
      <img
        *ngIf="element.stBloqueado == 'N'"
        src="../../assets/img/icon_active.png"
        border="0"
      />
      <img
        *ngIf="element.stBloqueado == 'S'"
        src="../../assets/img/icon_inactive.png"
        border="0"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="data_expiracao">
    <th mat-header-cell *matHeaderCellDef style="width: 15%">
      Data Inativação
    </th>
    <td mat-cell *matCellDef="let element" style="width: 15%">
      <div *ngIf="element.dtExpiracao">
        {{ element.dtExpiracao | date : "dd/MM/yyyy" }}
      </div>
      <div *ngIf="!element.dtExpiracao">NÃO ATRIBUÍDA</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="sistemas_perfis">
    <th mat-header-cell *matHeaderCellDef style="width: 30%">
      Sistemas / Perfis
    </th>
    <td mat-cell *matCellDef="let element" style="width: 30%">
      <div
        *ngIf="
          element.sistemaPerfis && element.sistemaPerfis.length > 0;
          else noProfiles
        "
      >
        <mat-tree
          [dataSource]="createTree(element.sistemaPerfis)"
          [treeControl]="treeControl"
          class="example-tree"
          style="background: transparent"
        >
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              <img
                src="../../assets/img/icon_circle.png"
                style="margin-right: 5px"
              />
              {{ node.name }}
            </li>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
              <div class="mat-tree-node">
                <button
                  mat-icon-button
                  matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                  </mat-icon>
                </button>
                {{ node.name }}
              </div>
              <ul
                [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
      <ng-template #noProfiles>
        <div style="margin-left: 20px">NÃO ATRIBUÍDOS</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="editar">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Editar</th>
    <td mat-cell *matCellDef="let element" style="width: 10%">
      <!-- Always allow editing for isPerfilGestorAnvisa -->
      <div
        *ngIf="isPerfilGestorAnvisa || isPerfilAdministrador; else otherRoles"
      >
        <a
          (click)="
            checkAndOpenDialogColabAtrPerfil(
              '0ms',
              '0ms',
              element.idUsuarioRepresentante,
              element.noPessoaFisica,
              false
            )
          "
          title="Editar"
        >
          <img src="../../assets/img/icon_edit.png" border="0" />
        </a>
      </div>

      <ng-template #otherRoles>
        <ng-container
          *ngIf="
            !isPerfilGestorCadastrosResponsavelLegal ||
              element.stBloqueado !== 'S';
            else blockedRole
          "
        >
          <div *ngIf="isPerfilGestorAnvisaConsulta; else allowedEdit">
            <img src="../../assets/img/icon_edit_disabled.png" border="0" />
          </div>
        </ng-container>

        <ng-template #allowedEdit>
          <a
            (click)="
              checkAndOpenDialogColabAtrPerfil(
                '0ms',
                '0ms',
                element.idUsuarioRepresentante,
                element.noPessoaFisica,
                false
              )
            "
            title="Editar"
          >
            <img src="../../assets/img/icon_edit.png" border="0" />
          </a>
        </ng-template>

        <ng-template #blockedRole>
          <img src="../../assets/img/icon_edit_disabled.png" border="0" />
        </ng-template>
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
