<h1 mat-dialog-title style="font-weight: bold; text-align: center;">
  Cadastro Anvisa - Dashboard
</h1>

<div mat-dialog-content style="padding: 20px 10px; font-size: 16px; line-height: 1.6;">
  <!-- Dashboard Data Display -->
  <ng-container *ngIf="(dashboardData$ | async) as dashboardData; else noData">
    <div style="display: flex; flex-direction: column; gap: 12px;">
      <!-- Total Updated Companies -->
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span style="font-weight: bold;">Total de CNPJs ligados ao GOV.BR:</span>
        <span>{{ dashboardData.totalUpdatedCompanies }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Legal Representatives -->
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span style="font-weight: bold;">Total de Responsáveis Legais logados com o GOV.BR:</span>
        <span>{{ dashboardData.totalLegalRepresentatives }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Physical Persons -->
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span style="font-weight: bold;">Total de CPFs logados com o GOV.BR:</span>
        <span>{{ dashboardData.totalPhysicalPersons }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Created Companies -->
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <span style="font-weight: bold;">Total de Organizações Criadas pelo Cadastro Anvisa:</span>
        <span>{{ dashboardData.totalCreatedCompanies }}</span>
      </div>
    </div>
  </ng-container>

  <!-- Fallback content when data is unavailable -->
  <ng-template #noData>
    <div style="text-align: center; padding: 20px;">
      <p>Dados do dashboard indisponíveis.</p>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions style="justify-content: center; padding: 10px;">
  <button mat-button color="primary" mat-dialog-close style="font-weight: bold; color: white; background-color: #0d47a1; padding: 8px 24px; border-radius: 4px;">
    Fechar
  </button>
</div>
